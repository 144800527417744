<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9308 23H8.06917C5.55403 23 3.5 20.9413 3.5 18.4308V7.56917C3.5 5.05869 5.55869 3 8.06917 3H18.9308C21.446 3 23.5 5.05869 23.5 7.56917V18.4308C23.5 20.946 21.446 23 18.9308 23Z"
      :fill="color"
    />
    <path
      d="M18.2677 7.05664H8.90454C7.57931 7.05664 6.5 8.17788 6.5 9.54409V15.5649C6.5 16.9358 7.58386 18.0523 8.90454 18.0523H12.2108L12.78 19.0699L13.3402 20.0734C13.4404 20.2571 13.6954 20.2571 13.8001 20.0734L14.3603 19.0699L14.9296 18.0523H18.2722C19.5975 18.0523 20.6768 16.9311 20.6768 15.5649V9.5488C20.6768 8.17788 19.5929 7.05664 18.2677 7.05664Z"
      fill="white"
    />
    <path
      d="M14.128 11.4658C13.4039 11.4658 12.8164 12.0641 12.8164 12.8038C12.8164 13.5434 13.4039 14.1417 14.128 14.1417C14.8521 14.1417 15.4395 13.5434 15.4395 12.8038C15.4395 12.0641 14.8566 11.4658 14.128 11.4658ZM14.128 13.4445C13.7819 13.4445 13.4995 13.1571 13.4995 12.8038C13.4995 12.4504 13.7819 12.1631 14.128 12.1631C14.4741 12.1631 14.7564 12.4504 14.7564 12.8038C14.7564 13.1571 14.4786 13.4445 14.128 13.4445Z"
      :fill="color"
    />
    <path
      d="M9.98007 11.4659C9.69772 11.4659 9.43358 11.579 9.22865 11.7721V10.665H8.5V14.0806H9.22865V13.8403C9.43358 14.0288 9.69316 14.1465 9.98007 14.1465C10.6313 14.1465 11.1596 13.5482 11.1596 12.8086C11.1596 12.0689 10.6359 11.4659 9.98007 11.4659ZM9.83434 13.4729C9.56565 13.4729 9.33339 13.308 9.2332 13.0677C9.20132 12.9876 9.17855 12.8981 9.17855 12.8039C9.17855 12.7097 9.19677 12.6201 9.2332 12.5401C9.33339 12.2998 9.56565 12.1349 9.83434 12.1349C10.1987 12.1349 10.4901 12.4317 10.4901 12.8039C10.4901 13.1761 10.1987 13.4729 9.83434 13.4729Z"
      :fill="color"
    />
    <path
      d="M17.7122 11.5225V11.7345C17.5072 11.5696 17.2522 11.4707 16.979 11.4707C16.3095 11.4707 15.7676 12.0502 15.7676 12.7615C15.7676 13.4729 16.3095 14.0524 16.979 14.0524C17.2568 14.0524 17.5118 13.9534 17.7122 13.7886V14.1466C17.7122 14.1466 17.6393 14.4999 17.1292 14.4999V15.1642C17.1292 15.1642 17.6803 15.2301 18.1721 14.7214C18.1721 14.7214 18.3998 14.4717 18.4499 14.1419V11.5225H17.7122ZM17.1156 13.4164C16.7467 13.4164 16.4507 13.1196 16.4507 12.7568C16.4507 12.3941 16.7513 12.0973 17.1156 12.0973C17.3752 12.0973 17.6029 12.248 17.7122 12.4647C17.7577 12.5543 17.7805 12.6532 17.7805 12.7568C17.7805 12.8605 17.7531 12.9641 17.7122 13.0489C17.6029 13.2703 17.3752 13.4164 17.1156 13.4164Z"
      :fill="color"
    />
    <path
      d="M11.2422 10.5994V11.2825C11.2422 11.2825 11.5792 11.3296 11.6429 11.6688V14.0808H12.3944V11.6688V11.6499C12.3488 11.3107 12.153 11.0563 12.153 11.0563C11.7249 10.5334 11.2422 10.5994 11.2422 10.5994Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 25,
    },
    height: {
      type: [Number, String],
      default: 25,
    },
    color: {
      type: String,
      default: "#ced4da",
    },
  },
};
</script>

<style></style>
