import { CustomError, APIError } from "@/utils/genCustomError";
import type { AxiosError } from "axios";

import { captureException } from "@sentry/vue";
import type { SeverityLevel } from "@sentry/vue";

export type SentryExceptionOption = {
  name?: string;
  message?: string;
  tags?: { [key: string]: any };
  level?: SeverityLevel;
  extraData?: any;
};

export const logError = (options: SentryExceptionOption = {}) => {
  const {
    name = "[CUSTOM ERROR]",
    message = "error occurs",
    tags = {},
    level = "error",
    extraData = {},
  } = options;
  const customErrorInstance = new CustomError({
    name,
    message,
  });
  captureException(customErrorInstance, {
    level,
    tags: tags,
    extra: { ...extraData },
  });
};

export const logAPIError = (
  error: AxiosError,
  options: SentryExceptionOption = {}
) => {
  const {
    name,
    message,
    tags = { type: "network" },
    level = "error",
    extraData = {},
  } = options;
  const apiErrorInstance = new APIError(error, name, message);
  captureException(apiErrorInstance, {
    level,
    tags,
    extra: {
      header: apiErrorInstance.config.headers,
      response: apiErrorInstance.response?.data,
      request: apiErrorInstance.request,
      ...extraData,
    },
  });
};
