import { getCurrentInstance, computed, unref } from "@vue/composition-api";
import { Store } from "vuex";

export const useStore = <T = any>() => {
  const vm = getCurrentInstance()?.proxy;

  if (!vm) {
    throw new Error("Cannot find vuex");
  }

  const store = computed(() => vm.$store as Store<T>);

  return unref(store);
};
