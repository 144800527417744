






import { defineComponent, ref } from "@vue/composition-api";
import { useMutationObserver } from "@vueuse/core";

export default defineComponent({
  emits: ["scroll:end"],
  setup() {
    const smartPhoneLayoutRef = ref<HTMLDivElement | null>(null);

    useMutationObserver(
      smartPhoneLayoutRef,
      (mutations) => {
        if (mutations[0]) {
          if (smartPhoneLayoutRef.value) {
            smartPhoneLayoutRef.value.style.height = "";
          }
        }
      },
      { attributes: true }
    );

    return { smartPhoneLayoutRef };
  },
});
