import { ref } from "vue-demi";
import { useLocalStorage } from "@vueuse/core";
import moment from "moment";

import { useRoute } from "@/libs/router";

export const useIntroPopup = () => {
  const isOpened = ref(false);
  const closedUntil = useLocalStorage(
    "intro_popup_closed_until",
    moment().subtract(1, "days").endOf("day").format()
  );
  const setClosedUntil = (
    amount: number,
    unit: moment.unitOfTime.DurationConstructor
  ) => {
    const newClosedUntil = moment()
      .add(amount, unit)
      .subtract(1, "days")
      .endOf("day")
      .format();

    closedUntil.value = newClosedUntil;
  };

  const route = useRoute();
  const currentRouteName = route.name;
  const excludePageNames = ["OrderResult", "OrderProcess"];
  const checkCloseTimePassed = () => {
    const current = moment();
    const closedUntilMoment = moment(closedUntil.value);

    return current.diff(closedUntilMoment) > 0;
  };

  if (
    checkCloseTimePassed() &&
    !excludePageNames.includes(currentRouteName as string)
  ) {
    isOpened.value = true;
  }

  const onCloseForDayOrNow = (forDay = false) => {
    isOpened.value = false;

    if (forDay) {
      setClosedUntil(1, "days");
    }
  };

  return {
    isOpened,
    closedUntil,
    setClosedUntil,
    onCloseForDayOrNow,
  };
};

export default useIntroPopup;
