







































import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "@vue/composition-api";
import axios from "axios";
import { watchOnce } from "@vueuse/core";

import { Shop } from "@/components/Shop/Types";

import requestShopInfo from "@/components/Shop/Hooks/requestShopInfo";
import { useRouter } from "@/libs/router";
import { checkServiceDelayed } from "@/api/others";
import useIntroPopup from "@/components/Hooks/useIntroPopup";

import BaseLayout from "@/components/Layout/BaseLayout.vue";
import MyshopInfoLayout from "@/components/Layout/MyshopInfoLayout.vue";
import SmartPhoneLayout from "@/components/Layout/SmartPhoneLayout.vue";
import { TEMP_STORE_ID } from "@/router";

export default defineComponent({
  components: {
    BaseLayout,
    MyshopInfoLayout,
    SmartPhoneLayout,
  },
  props: {
    shopUrl: {
      type: String,
      required: true,
    },
  },
  inheritAttrs: false,
  setup(props) {
    const router = useRouter();

    const shopInfo = ref<Shop>();

    const {
      isOpened: isIntroPopupOpened,
      onCloseForDayOrNow: onCloseIntroPopup,
    } = useIntroPopup();
    const introPopupInfo = {
      src: "https://tagbyimg.s3.ap-northeast-2.amazonaws.com/assets/store/new-year-banner-240202.png",
      alt: "2024 추석 연휴 배송 안내 : 집하는 2/5일(월) 마감됩니다. 2월 13일 이후 순차 배송됩니다.",
    };
    isIntroPopupOpened.value = false;

    onMounted(async () => {
      try {
        const data = await requestShopInfo(props.shopUrl ?? TEMP_STORE_ID);

        shopInfo.value = data;
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.data.code === 128) {
            router.push("/404");
          } else {
            alert("스토어 불러오기에 실패했습니다. 다시 시도해주세요.");
          }
        } else {
          alert("스토어 불러오기에 실패했습니다. 다시 시도해주세요.");
        }
      }
    });

    const isServiceDelayed = ref(false);
    watchOnce(isServiceDelayed, (newValue) => {
      if (newValue) {
        alert(
          "갑작스러운 서버 이슈로 접속이 지연되고 있어, 빠르게 개선 중입니다. 신속히 조치하겠습니다."
        );
      }
    });

    onMounted(async () => {
      if (isIntroPopupOpened.value) {
        document.body.classList.add("modal-open");
      }

      isServiceDelayed.value = await checkServiceDelayed();
    });

    watch(
      () => isIntroPopupOpened.value,
      (newValue) => {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      }
    );

    onUnmounted(() => {
      document.body.classList.remove("modal-open");
    });

    return {
      shopInfo,
      isIntroPopupOpened,
      introPopupInfo,

      onCloseIntroPopup,
    };
  },
});
