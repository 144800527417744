import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { logAPIError } from "@/utils/handleErrorLog";

export const BASE_API_URL = process.env.VUE_APP_BACKEND_SERVER || "";
export const BASE_ASYNC_URL = process.env.VUE_APP_ASYNC_BACKEND_SERVER || "";
export const BASE_API_DEBUG_URL =
  process.env.VUE_APP_DEBUG_BACKEND_SERVER || "";

export const requestHandler = async (config: AxiosRequestConfig) => {
  return config;
};

export const responseHandler = (response: AxiosResponse) => {
  return response;
};

export const errorHandler = (error: AxiosError) => {
  logAPIError(error);
  return Promise.reject(error);
};
