import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCompositionApi from "@vue/composition-api";
import i18n from "./i18n";
import VueCroppie from "vue-croppie";
import VueGtm from "@gtm-support/vue2-gtm";
import VueClipboard from "vue-clipboard2";
import initGlobalAxiosInterceptor from "@/utils/initGlobalAxiosInterceptor";
import * as Sentry from "@sentry/vue";

try {
  window.Kakao.init(process.env.VUE_APP_COMMERCE_KAKAO_APP_ID);
} catch {
  console.log("KAKAO SDK init error");
}

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID_STORE,
  defer: false,
  compatibility: false,
  enabled: true,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});

Vue.use(VueCroppie);
Vue.use(VueClipboard);

// Composition API
Vue.use(VueCompositionApi);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_COMMERCE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: process.env.VUE_APP_COMMERCE_SENTRY_ENVIRONMENT,
  enabled: process.env.VUE_APP_COMMERCE_SENTRY_ENVIRONMENT === "production",

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

initGlobalAxiosInterceptor();
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
