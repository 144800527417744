import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
// types
import { SelectedProduct } from "@/components/Product/Types";

Vue.use(Vuex);

/* state types */
export interface State {
  cartItems: { [shopIdx: number]: SelectedProduct[] };
  orderProducts: { [shopIdx: number]: SelectedProduct[] };
  selectedCartIndices: { [shopIdx: number]: number[] };
  cartVersion: string;
}

const LATEST_CART_VERSION = "1.1.0";
const CART_VERSION_FALLBACK = "0.0.0";

const store: StoreOptions<State> = {
  state: {
    cartItems: {},
    orderProducts: {},
    selectedCartIndices: {},
    cartVersion: CART_VERSION_FALLBACK,
  },
  mutations: {
    setCartItems(state, payload: { [shopIdx: number]: SelectedProduct[] }) {
      state.cartItems = payload;
    },
    initializeStore(state) {
      if (localStorage.getItem("orderProducts")) {
        state.orderProducts = JSON.parse(
          localStorage.getItem("orderProducts") as string
        );
      } else {
        state.orderProducts = {};
      }

      state.cartVersion =
        localStorage.getItem("cartVersion") ?? CART_VERSION_FALLBACK;

      if (state.cartVersion !== LATEST_CART_VERSION) {
        localStorage.setItem("cartVersion", LATEST_CART_VERSION);
        state.cartVersion = LATEST_CART_VERSION;
        localStorage.setItem("cart", JSON.stringify({}));
      }

      state.cartItems =
        JSON.parse(localStorage.getItem("cart") as string) ?? {};

      state.selectedCartIndices =
        JSON.parse(localStorage.getItem("selectedCartIndices") as string) ?? {};
    },
    setOrderProducts(state, payload: { [shopIdx: number]: SelectedProduct[] }) {
      state.orderProducts = { ...state.orderProducts, ...payload };
      localStorage.setItem(
        "orderProducts",
        JSON.stringify(state.orderProducts)
      );
    },
    deleteOrderProducts(state, payload: { shopIdx: number }) {
      if (state.orderProducts[payload.shopIdx]) {
        delete state.orderProducts[payload.shopIdx];
        localStorage.setItem(
          "orderProducts",
          JSON.stringify(state.orderProducts)
        );
      }
    },
    setSelectedCartIndices(state, payload: { [shopIdx: number]: number[] }) {
      state.selectedCartIndices = { ...state.selectedCartIndices, ...payload };
      localStorage.setItem(
        "selectedCartIndices",
        JSON.stringify(state.selectedCartIndices)
      );
    },
    deleteSelectedCartIndices(state, payload: { shopIdx: number }) {
      if (state.selectedCartIndices[payload.shopIdx]) {
        delete state.selectedCartIndices[payload.shopIdx];
        localStorage.setItem(
          "selectedCartIndices",
          JSON.stringify(state.selectedCartIndices)
        );
      }
    },
    setCartVersion(state, payload: { cartVersion: string }) {
      const { cartVersion } = payload;

      state.cartVersion = cartVersion;
      localStorage.setItem("cartVersion", state.cartVersion);
    },
  },
  actions: {
    setCartVersion({ commit }, payload: { cartVersion: string }) {
      commit("setCartVersion", payload);
    },
  },
  modules: {},
};

export default new Vuex.Store(store);
