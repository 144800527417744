import axios from "axios";
import {
  requestHandler,
  responseHandler,
  errorHandler,
} from "@/libs/axios/config";

export default () => {
  axios.interceptors.request.use(requestHandler, errorHandler);
  axios.interceptors.response.use(responseHandler, errorHandler);
};
