


















import { defineComponent, ref } from "@vue/composition-api";
import { useMutationObserver } from "@vueuse/core";

export default defineComponent({
  props: {
    backgroundColor: {
      type: String,
      required: true,
    },
  },
  inheritAttrs: false,
  setup() {
    const wrapRef = ref<HTMLDivElement | null>(null);

    useMutationObserver(
      wrapRef,
      (mutations) => {
        if (mutations[0]) {
          if (wrapRef.value) {
            wrapRef.value.style.height = "";
          }
        }
      },
      { attributes: true }
    );

    const contentAreaRef = ref<HTMLDivElement | null>(null);

    useMutationObserver(
      contentAreaRef,
      (mutations) => {
        if (mutations[0]) {
          if (contentAreaRef.value) {
            contentAreaRef.value.style.height = "";
          }
        }
      },
      { attributes: true }
    );

    return {
      wrapRef,
      contentAreaRef,
    };
  },
});
