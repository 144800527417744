import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Myshop from "@/views/Myshop.vue";
import camelcaseKeys from "camelcase-keys";

Vue.use(VueRouter);

/**
 * 네이버페이 심사를 위한 하드코딩
 * 심사가 끝날 시 해당 코드 삭제바람
 */
export const TEMP_STORE_ID = "junro79";

const routes: Array<RouteConfig> = [
  // {
  //   path: "/hello",
  //   name: "HelloWorld",
  //   component: HelloWorld,
  // },
  // {
  //   path: "/vuex",
  //   name: "VuexWorld",
  //   component: VuexWorld,
  // },
  // {
  //   path: "/chart",
  //   name: "ChartWorld",
  //   component: ChartWorld,
  // },
  // {
  //   path: "/international",
  //   name: "InternationalWorld",
  //   component: InternationalWorld,
  // },
  // {
  //   path: "/nicepay",
  //   name: "NicepayAuthPay",
  //   component: NicepayAuthPay,
  // },
  // {
  //   path: "/nicepay/result",
  //   name: "NicepayAuthPayPost",
  //   component: NicepayAuthPayPost,
  // },
  // TODO: index 페이지 추가
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => import("@/views/Home.vue"),
  //   beforeEnter(to, from, next) {
  //     window.location.href = "https://www.tagby.io/influencer/landing";
  //     next();
  //   },
  // },

  /**
   * 네이버페이 심사를 위한 하드코딩
   * 심사가 끝날 시 해당 코드 삭제바람
   */
  {
    path: `/id/${TEMP_STORE_ID}`,
    component: Myshop,
    alias: "/",
    children: [
      {
        path: "",
        name: "TmpMyshop",
        component: () => import("@/views/MyshopProducts.vue"),
      },
      {
        path: "product/:productNo",
        name: "TmpMyshopProduct",
        component: () => import("@/views/MyshopProduct.vue"),
        props: true,
      },
      {
        // params : { productList: JSON.stringify(SelectedProduct[]) }
        path: "order",
        name: "TmpOrderPage",
        component: () => import("@/views/OrderPage.vue"),
        props: true,
        children: [
          {
            path: "terms/:termType",
            name: "TmpOrderTerms",
            component: () => import("@/views/OrderTerms.vue"),
            props: true,
          },
        ],
      },
      {
        path: "order/result/:orderNo",
        name: "TmpOrderResult",
        component: () => import("@/views/OrderResult.vue"),
        props: true,
      },
      {
        path: "order/process",
        component: () => import("@/views/OrderProcess.vue"),
        name: "TmpOrderProcess",
        props: true,
      },
      {
        path: "cart",
        name: "TmpCart",
        component: () => import("@/views/Cart.vue"),
        props: true,
      },

      {
        path: "delivery",
        component: () => import("@/views/Delivery.vue"),
        name: "TmpDelivery",

        props: (route) => {
          const { orderNo: givenOrderGroupNo } = camelcaseKeys(route.query, {
            deep: true,
          });

          return {
            givenOrderGroupNo,
          };
        },
      },
    ],
    props: true,
  },

  {
    path: "/id/:shopUrl",
    component: Myshop,
    children: [
      {
        path: "",
        name: "Myshop",
        component: () => import("@/views/MyshopProducts.vue"),
      },
      {
        path: "product/:productNo",
        name: "MyshopProduct",
        component: () => import("@/views/MyshopProduct.vue"),
        props: true,
      },
      {
        // params : { productList: JSON.stringify(SelectedProduct[]) }
        path: "order",
        name: "OrderPage",
        component: () => import("@/views/OrderPage.vue"),
        props: true,
        children: [
          {
            path: "terms/:termType",
            name: "OrderTerms",
            component: () => import("@/views/OrderTerms.vue"),
            props: true,
          },
        ],
      },
      {
        path: "order/result/:orderNo",
        name: "OrderResult",
        component: () => import("@/views/OrderResult.vue"),
        props: true,
      },
      {
        path: "order/process",
        component: () => import("@/views/OrderProcess.vue"),
        name: "OrderProcess",
        props: true,
      },
      {
        path: "cart",
        name: "Cart",
        component: () => import("@/views/Cart.vue"),
        props: true,
      },
      {
        path: "delivery",
        component: () => import("@/views/Delivery.vue"),
        name: "Delivery",
        // children: [
        //   {
        //     path: ":orderGroupNumber",
        //     name: "Delivery",
        //     component: Delivery,
        //   },
        // ],
        props: (route) => {
          const { orderNo: givenOrderGroupNo } = camelcaseKeys(route.query, {
            deep: true,
          });

          return {
            givenOrderGroupNo,
          };
        },
      },
    ],
    props: true,
  },
  { path: "/404", component: () => import("@/views/NotFound.vue") },
  { path: "/*", component: () => import("@/views/NotFound.vue") },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (from.name === "OrderTerms" && to.name === "OrderPage") {
      return;
    } else if (to.name === "OrderPage") {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
