import axios from "axios";
import camelcaseKeys from "camelcase-keys";

const requestShopInfo = async (urlPath: string) => {
  const { data } = await axios.get(
    `${process.env.VUE_APP_BACKEND_SERVER}/myshop/info/?url_path=${urlPath}`
  );

  const camelCaseData = camelcaseKeys(data.data, { deep: true });

  return camelCaseData;
};

export default requestShopInfo;
