




import { defineComponent } from "@vue/composition-api";
import { useStore } from "@/libs/store";

export default defineComponent({
  setup(props, context) {
    const store = useStore();
    store.commit("initializeStore");

    // TODO: 10월 이후 삭제
    localStorage.removeItem("cartItems");
    localStorage.removeItem("cartItems2");
  },
});
