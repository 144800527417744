import { getCurrentInstance, computed, unref } from "@vue/composition-api";
import VueRouter, { Route } from "vue-router";

export interface Router extends VueRouter {
  isReady: () => Promise<void>;

  /** @deprecated */
  app: VueRouter["app"];

  /** @deprecated */
  getMatchedComponents: VueRouter["getMatchedComponents"];

  /** @deprecated use `isReady` instead */
  onReady: VueRouter["onReady"];
}

const useRoute = () => {
  const vm = getCurrentInstance()?.proxy;

  if (!vm) {
    throw new Error("Cannot find vue-router");
  }

  const route = computed(() => vm.$route as Route);

  return unref(route);
};

const useRouter = () => {
  const vm = getCurrentInstance()?.proxy;

  if (!vm) {
    throw new Error("Cannot find vue-router");
  }

  return vm.$router as Router;
};

export { useRoute, useRouter };
