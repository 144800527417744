




































































import { defineComponent, computed, ref } from "@vue/composition-api";

import { Shop } from "@/components/Shop/Types";

import checkDarkTheme from "@/components/Shop/Hooks/checkDarkTheme";

import InstagramLogo from "@/assets/Logo/InstagramGradientBorderLogo.vue";
import BlogLogo from "@/assets/Logo/BlogLogoSquare.vue";
import YoutubeLogo from "@/assets/Logo/YoutubeLogo.vue";
import ProfileIcon from "@/assets/ElementsImage/ProfileIcon2.vue";

export default defineComponent({
  components: {
    InstagramLogo,
    BlogLogo,
    YoutubeLogo,
    ProfileIcon,
  },
  props: {
    shopInfo: {
      type: Object as () => Shop,
      required: true,
    },
  },
  setup(props) {
    const isDarkTheme = computed(() => {
      if (!props.shopInfo) return false;

      const isDark = checkDarkTheme(props.shopInfo.color);

      return isDark;
    });

    const isProfileImgBroken = ref(false);

    const hasSns = computed(() => {
      if (!props.shopInfo) return false;

      return Object.values(props.shopInfo.snsList).some((sns) => !!sns);
    });

    return {
      isDarkTheme,
      isProfileImgBroken,
      hasSns,
    };
  },
});
