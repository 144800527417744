import axios from "axios";
import camelcaseKeys from "camelcase-keys";

export const checkServiceDelayed = async (): Promise<boolean> => {
  const res = await axios.get(
    `${process.env.VUE_APP_BACKEND_SERVER}/myshop/is-delay/`
  );

  const data = camelcaseKeys(res.data.data, { deep: true });

  return data.isDelay;
};
