





























import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    width: {
      type: [Number, String],
      default: 21,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
    gradientId: {
      type: String,
      default: "g-id",
    },
  },
});
