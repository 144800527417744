interface ErrorParams {
  name: string;
  message: string;
}

export class CustomError extends Error {
  constructor(error: ErrorParams) {
    super(error.name);
    this.name = error.name;
    this.message = error.message;
  }
}

import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

export class APIError<T = any> extends Error implements AxiosError<T> {
  config: AxiosRequestConfig;
  code?: string;
  request?: any;
  response?: AxiosResponse<T>;
  isAxiosError: boolean;
  toJSON: () => any;

  constructor(error: AxiosError<T>, name?: string, message?: string) {
    super(message ?? error.message);

    const errorStatus = error.response?.status || "ERROR";

    this.name = name ?? `[${errorStatus}] ${error.config.url}`;
    this.stack = error.stack;

    this.config = error.config;
    this.code = error.code;
    this.request = error.request;
    this.response = error.response;
    this.isAxiosError = error.isAxiosError;
    this.toJSON = error.toJSON;
  }
}
