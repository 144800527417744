const checkDarkTheme = (colorCode: string): boolean => {
  const darkThemeColors = [
    "#000000",
    "#f2bac2",
    "#c7adb5",
    "#945c31",
    "#b3bfc0",
    "#b7b0b1",
    "#7a7a63",
    "#666b71",
    "#b58b79",
  ];
  //   const lightThemeColors = [
  //     "#ffffff",
  //     "#fbf4e7",
  //     "#fdf4b1",
  //     "#ddece2",
  //     "#ddd5ba",
  //     "#f3ccc4",
  //     "#f2bac2",
  //   ];

  if (darkThemeColors.includes(colorCode.toLowerCase())) {
    return true;
  }

  return false;
};

export default checkDarkTheme;
